<template>
  <p
    v-if="!isLocaleActive && config.public.isPreviewEnabled"
    :class="$style.message"
  >
    The current locale is not active: {{ $resolvedLocale }}
  </p>
</template>

<script lang="ts" setup>
interface Props {
  isLocaleActive: boolean;
}

defineProps<Props>();

const { $resolvedLocale } = useNuxtApp();
const config = useRuntimeConfig();
</script>

<style module>
.message {
  text-align: center;
  background-color: #da1b1b;
  color: white;
  padding: 0.5rem 0;
  margin: 0;
  position: sticky;
  top: 88px;
  z-index: 99;
}

@media (max-width: 1200px) {
  .message {
    top: 78px;
  }
}
</style>
